@import 'theme';
@import 'quicksand-font';


.help-container{
    font-family: Quicksand;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-content: center;
    width: 100%;
    min-height: calc(100vh - 2rem - 135px);
    background-color: $fifth-color;

    h3 { 
        font-size: 0.75rem; 
    }

    div{
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 40vw;
        max-width: 600px;
        min-width: 300px;       
        align-self: center; 
    }

    .symbol-def-container{
        display: block;

        ul{
            li{
                font-size: 0.75rem;
                list-style: none;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                align-items: center;
                justify-content: left;

                padding: 5px;
                margin: 0;

                img{
                    height: 1rem;
                    width: 1rem;
                    padding: 0  10px 0 0;
                    margin: 0;
                }

                p{
                    font-size: 0.65rem;
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }

    .display-def-container{
        flex-direction: column;
        img{
            width: 100%;
            max-width: 400px;
            align-self: center;
        }
    }

    .button-box{
        width: 100%;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        justify-content: center;

        a{
            line-height: 1rem;
            height: 1rem;
            width: auto;
            border: none;
            padding: 0.2rem;
            margin: 1rem;
            text-decoration: none;
            font-size: 0.65rem;
            font-weight: 600;
            display: inline-block;
            border-radius: 5px;
            cursor: pointer;
            transition: all ease-out 0.1s;
            outline: none;
            color: $primary-color;
            background-color: $third-color;
        }
    
        a:hover{
            background-color: rgba($color: $fifth-color, $alpha: 0.7);
        }
    }
}