@import 'theme';
@import 'quicksand-font';


.data-card-history {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    width: 100%;


    h4 {
        width: 100%;
        font-size: 0.5rem;
        margin: 0.4rem 0 0.3rem 0;
        text-align: center;
        background-color: $fifth-color;
    }

    .legend{
        width: 95%;
        max-width: 603px;
        font-size: 0.4rem;
        margin: auto;
        th{
            text-align: center;
        }
    }

    table {
        font-size: 0.4rem;
        border-collapse: separate;
        border-spacing: 0;

        th, td{
            height: 15px;
            background-color: $third-color;
            div{
                display: block;
                background-color: $primary-color;
                border-radius: 100%;
                margin: auto;
            }
            .break-point-1{
                height: 2.5px;
                width: 2.5px;
            }

            .break-point-2{
                height: 5px;
                width: 5px;
            }

            .break-point-3{
                height: 7.5px;
                width: 7.5px;
            }

            .break-point-4{
                height: 10px;
                width: 10px;
            }

            .break-point-5{
                height: 12.5px;
                width: 12.5px;
            }
        }
    }

    .history-chart-container {
        width: 90%;
        max-width: 603px;
        position: relative;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-grow: 1;
        align-self: center;
        margin: auto;
        overflow: auto;
        margin-top: 0.6rem;

        .history-chart {
            tr{
                height: 15px;
            }
            td{
                height: 15px;
                min-height: 15px;
                width: 15px;
                min-width: 15px;
            }
            .today{
                box-sizing: border-box;
                border: 2px solid $second-color;
            }
            thead th{
                top: 0;
                height: 15px;
                width: 15px;
                position: -webkit-sticky;
                position: sticky;
                z-index: 1;  
                overflow: hidden;
                text-align: center;
                vertical-align: middle;
                background-color: $fifth-color;
            }

            thead th:first-child{ 
                z-index: 3; 
            }

            th:first-child {
                position: -webkit-sticky;
                position: sticky;
                left: 0;
                z-index: 2;
                text-align: left;
            }
        }

        table.history-chart tr:hover td{
            background-color: $fifth-color;//rgba($color: $fifth-color, $alpha: 1.5);
        }
        
        table.history-chart tbody tr:hover th{
            background-color: $fifth-color; //rgba($color: $fifth-color, $alpha: 0.5);
        }
    }

    .history-chart-container::-webkit-scrollbar {
        height: 0.2rem;
        width: 0.2rem;
    }
       
    .history-chart-container::-webkit-scrollbar-track {
        background-color: $fifth-color; 
    }
       
    .history-chart-container::-webkit-scrollbar-thumb {
        background-color: $primary-color;
        border-radius: 5px;
        background-clip: content-box; 
    }
}