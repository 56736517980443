@import 'theme';
@import 'quicksand-font';

.data-card-desc{
    width: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding-bottom: 1rem;

    h4{
        line-height: 1rem;
        font-size: 0.6rem;
        height: 1rem;
        margin: 0.2rem;
    }

    p{
        font-size: 0.6rem;
        margin: 0 1.2rem 0 1.2rem;
    }

    a {
        display: inline-block;
        text-decoration: none;
        color: $primary-color;
        font-size: 0.5rem;
        font-weight: 300;
        position: relative;
        margin: 0 1.2rem 0 1.2rem;
    }

    ul{
        margin: auto 0;
        font-size: 0.6rem;
        text-align: left;
        li{
            width: 50vw;

            a { 
                //display: inline-block;
                font-size: 0.6rem;
                margin: 0;
            }
        }
    }

    a:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0;
        left: 0;
        background-color: $primary-color;
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out;
    }

    a:hover:before {
        visibility: visible;
        transform: scaleX(1);
    }

    .overview-box{
        //height: 1.4rem;
        width: 100%;
        margin: 0.1rem 0 0.1rem 0;
        background-color: $fifth-color;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;       
        
        .gage-box, .diff-box{
            font-size: 0.5rem;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            flex-direction: row;
            a{
                margin-left: 0px;
                .value{ 
                    font-weight: 300;
                    color: $primary-color;
                    margin-left: 0;
                    padding-left: 0; 
                }
            }
        }
    }
}

.data-card-desc::-webkit-scrollbar {
    width: 0.2rem;
  }
   
.data-card-desc::-webkit-scrollbar-track {
    margin-top: 1px;
    background-color: $fifth-color; 
    border-radius: 5px;
}
   
.data-card-desc::-webkit-scrollbar-thumb {
    background-color: $primary-color;
    border-radius: 5px;
    background-clip: content-box; 
}