@import 'theme';
@import 'quicksand-font';


.data-card-deck{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    min-height: calc(100vh - 2rem - 135px);
    width: 100%;
    background-color: $fifth-color;

    ul{
        margin: 1rem;
        padding: 0;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;

        @media screen and (max-width: $media-break1) {
            margin: 0;
        }
    }

    .data-card{
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        margin: 0.2rem 10vw 0.2rem 10vw;
        padding: 0.2rem;
        border-radius: 0.2rem;
        font-family: Quicksand;
        width: $content-width - 10;
        list-style: none;
        background-color: $third-color;

        @media screen and (max-width: $media-break1) {
            margin: 0.2rem 2px 0.2rem 2px;
            width: 90vw;
        }

        .detail-button{display: none;}

        .detail-button + .detail-button-view{
            position: relative;

            .data-card-display{
                position: relative;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                justify-items: center;

                h2{ 
                    margin: 0;
                    padding: 0 0 0 5px;
                    line-height: $head-height;
                    font-size: 0.75rem;

                    @media screen and (max-width: $media-break2) {
                        font-size: 0.6rem;
                    }
                }

                .status-icon{
                    height: $head-height;
                    width: $head-height / 2;
                    padding: 0 0.2rem 0 0.2rem;

                    display: -webkit-flex;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img{
                        display: block;
                        height: $head-height / 2;
                        width: $head-height / 2;
                    }
                }

                .spot-values{
                    right: 0;
                    position: absolute;
                    display: -webkit-flex;
                    display: -ms-flexbox;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: $head-height;
                    text-align: right;

                    div{
                        display: -webkit-flex;
                        display: -ms-flexbox;
                        display: flex;
                        justify-content: center;

                        h5{
                            font-weight: 300;
                            font-size: 0.40rem;
                            padding-left: 10px;
                        }

                        .flow-flat{ color: $fifth-color; }
                        .flow-negative{ color: $fourth-color; }
                        .flow-positive{ color: $primary-color; }
                    }
                    
                    h3, h4, h5{
                        margin: 0;
                        padding: 0;
                        line-height: $head-height / 2;
                    }

                    h3{ 
                        font-size: 0.7rem; 

                        @media screen and (max-width: $media-break2) {
                            font-size: 0.6rem;
                        }
                    }
    
                    h4{
                        font-weight: 300;
                        font-size: 0.5rem;

                        @media screen and (max-width: $media-break2) {
                            font-size: 0.4rem;
                        }
                    }
                }
            }

        }

        // makes spot details visible
        .detail-button:checked + .detail-button-view{
            .data-card-detail{
                height: 50vh;
            }
        }
    }
}