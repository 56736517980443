@import 'theme';
@import 'lobster-font';
@import 'quicksand-font';

header {
    height: $head-height;
    min-width: 350px;
    width: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: fixed;
    top: 0;
    background-color: $primary-color;
    padding: 0;
    margin: 0;
    align-items: center;
    z-index: 100;

    h1{
        padding: 0;
        margin: 0;
        font-family: Lobster;
        color: $third-color;
        font-weight: 100;
        font-size: 1rem;

        @media screen and (max-width: $media-break1) {
            font-size: 0.7rem;
        }
    }

    img{
        height: 1.70rem;
        width: 1.70rem;
        padding: 0 0.5rem 0 0.5rem;
    }

    // hides checkbox
    .map-button{display: none;}

    // map button
    .map-button + .map-button-display{
        height: 1.1rem;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0.4rem;
        border: 1px solid $third-color;
        border-radius: 0.2rem;
        cursor: pointer;

        h2{
            display: inline-block;
            color: $third-color;
            font-family: Quicksand;
            font-size: 0.6rem;
            font-weight: 500;
            margin: 0.2rem;

            @media screen and (max-width: $media-break1) {
                font-size: 0.5rem;
            }
        }

        svg{
            height: 0.5rem;
            width: 0.5rem;
            fill: $third-color;
            padding: 0.2rem;
            transform: rotate(180deg);
            transition: all 0.5s;

            @media screen and (max-width: $media-break1) {
                height: 0.3rem;
                width: 0.3rem;
                padding: 0.2rem;
            }
        }


    }

    // updates map button apearance on click
    .map-button:checked + .map-button-display{
        svg{
            transform: rotate(0deg);
        }
    }

    .map-button + .map-button-display .hidden{
        visibility: hidden;
    }

}