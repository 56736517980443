
$primary-color: #01796F;
$second-color: #F9A602;
$third-color: #FFF;
$fourth-color: #C23B22;
$fifth-color: #D3D3D3;

$font-size: 30px;
$head-height: 2rem;
$content-width: 70vw;

$media-break1: 750px;
$media-break2: 500px;
$media-break3: 370px;

$detail-header-height: 1.35rem;
$footer-height: 135px;