@import 'theme';
@import 'quicksand-font';

.map-container{
    position: relative;
    min-height: 300px;
    height: 50vh;
    width: 70vw;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    background-color: #B2B2B2B2;
    padding: 0;
    margin: 0;
    transition: all 0.5s;
    font-family: Quicksand;

    .map{
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #B2B2B2B2;

        .info-window{
            padding: 5px;
            text-decoration: none;
            color: #000;

            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h2{
                font-size: 0.45rem;
                font-weight: 300;
            }

            a{
                color: $third-color;
                background-color: $primary-color;
                padding: 0.2rem;
                border-radius: 5px;
                text-decoration: none;
                font-size: 0.4rem;
            }
        }
    }

    @media screen and (max-width: $media-break1) {
        height: 60vh;
        width: 100vw;  
    }
}

.hidden{
    height: 0; 
    min-height: 0;
    visibility: hidden;
}