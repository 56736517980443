@import 'theme';


html,body{
    font-size: $font-size;
    margin: 0;
    padding: 0;

    #root{
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;

        .container{
            min-height: calc(100vh - 135px);
            width: 100%;
            min-width: 350px;
            min-width: -webkit-fill-available;
            position: relative;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 auto; 
        }
    }

    body::-webkit-scrollbar {
        height: 0.2rem;
        width: 0.2rem;
    }
       
    body::-webkit-scrollbar-track {
        background-color: $fifth-color; 
    }
       
    body::-webkit-scrollbar-thumb {
        background-color: $primary-color;
        background-clip: content-box; 
    }
}