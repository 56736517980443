@font-face {
    font-family: 'Quicksand';
    src:url('fonts/quicksand/static/Quicksand-Light.ttf') format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: 'Quicksand';
    src:url('fonts/quicksand/static/Quicksand-Medium.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Quicksand';
    src:url('fonts/quicksand/static/Quicksand-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Quicksand';
    src:url('fonts/quicksand/static/Quicksand-SemiBold.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Quicksand';
    src:url('fonts/quicksand/static/Quicksand-Bold.ttf') format('truetype');
    font-weight: 600;
}