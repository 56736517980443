@import 'theme';

.content{
    position: absolute;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    top: $head-height;
    align-items: center;
    width: 100%;
    background-color: $fifth-color;
    min-height: calc(100vh - 2rem);

    @media screen and (max-width: $media-break1) {
        width: 100%;  
    }

    .cover{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba($color: $third-color, $alpha: 1.0);
        z-index: 99;
    }
}
